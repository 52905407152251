import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import IndexTable from './IndexTable';
import callFetch from "../../helpers/callFetch";

function UpdateCheck(props) {

    const [systemInfo, setSystemInfo] = useState({});
    const [updating, setUpdating] = useState(false);

    useEffect(() => {
        if (!props?.data?.appKey) return;
        if (!props?.availableversion) return;
        callFetch("app/get-system-info" + '?appKey=' + props?.data?.appKey + '&availableVersion=' + props?.availableversion, "GET", {}, {}, props?.data?.api_url).then((res) => {
            if (!res?.data) return;
            setSystemInfo(res?.data);
        });
    }, [props?.data?.appKey, props?.availableversion, props?.data?.api_url]);


    return (
        <>
            {systemInfo && systemInfo?.updated == 1 ? (
                <>{systemInfo?.current_version}</>
            ) : systemInfo ? (
                <>
                    {updating == props?.data?.id ? 'Updating ...' : 'Update Available ' + systemInfo?.available_version}
                </>
            ) : ''}
        </>
    );
}

export default UpdateCheck;
