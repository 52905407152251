import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
//import Sidenav from "examples/Sidenav";
//import Configurator from "examples/Configurator";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";
//import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
//import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import { ToastContainer } from 'react-toastify';

// Soft UI Dashboard PRO React routes
//import routes from "routes";

//import brand from "assets/images/logo-ct.png";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";


import 'react-toastify/dist/ReactToastify.css';
import ApplicationSettings from "./pages/ApplicationSettings";
import PrivateOutlet from "./components/PrivateOutlet";
import Signin from "./pages/Signin";
import IllustrationSignIn from "layouts/authentication/sign-in/illustration/index"

import RoleIndex from './pages/role/RoleIndex';
import RoleEdit from './pages/role/RoleEdit';
import RoleCreate from './pages/role/RoleCreate';

import EmployeeCreate from './pages/employee/EmployeeCreate';
import EmployeeIndex from './pages/employee/EmployeeIndex';
import EmployeeEdit from './pages/employee/EmployeeEdit';

import Dashboard from './pages/dashboard/Index';

import Settings from './pages/settings/Index';

import SmtpIndex from './pages/smtp/SmtpIndex';
import SmtpCreate from './pages/smtp/SmtpCreate';
import SmtpEdit from './pages/smtp/SmtpEdit';

import AppsIndex from "pages/apps/Index";
import AppsCreate from "pages/apps/Create";
import AppsInstall from "pages/apps/Install";

import AppEmployeeCreate from "pages/apps/users/EmployeeCreate";
import AppEmployeeEdit from "pages/apps/users/EmployeeEdit";


//import RoofPlannerV4 from "pages/project/RoofPlannerV4";

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <SoftBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </SoftBox>
  );

  return (
    <>
      {/*<CacheProvider value={rtlCache}>*/}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          {/*<Route path="/" element={<Signin />} />*/}
          <Route path="/" element={<IllustrationSignIn />} />
          <Route path="/*" element={<PrivateOutlet />}>
            <Route path="application-settings" element={<ApplicationSettings />} />

            <Route path="dashboard" element={<Dashboard />} />
            <Route path="profile/settings" element={<Settings />} />

            <Route path="employees" element={<EmployeeIndex />} />
            <Route path="employees/create" element={<EmployeeCreate />} />
            <Route path="employees/:id" element={<EmployeeEdit />} />

            <Route path="roles" element={<RoleIndex />} />
            <Route path="roles/create" element={<RoleCreate />} />
            <Route path="roles/:id" element={<RoleEdit />} />

            <Route path="settings/smtp" element={<SmtpIndex />} />
            <Route path="settings/smtp/create" element={<SmtpCreate />} />
            <Route path="settings/smtp/:id" element={<SmtpEdit />} />

            <Route path="apps" element={<AppsIndex />} />
            <Route path="apps/create" element={<AppsCreate />} />
            <Route path="apps/:id/:tab" element={<AppsInstall />} />
            <Route path="apps/:id/:tab/create" element={<AppEmployeeCreate />} />
            <Route path="apps/:id/:tab/edit/:user_id" element={<AppEmployeeEdit />} />

          </Route>
        </Routes>

        {/* <Configurator />
                {configsButton}*/}

        <ToastContainer />

      </ThemeProvider>
      {/*</CacheProvider>*/}
    </>
  );
}
