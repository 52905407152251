import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "../../../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import Switch from "@mui/material/Switch";
import SoftSnackbar from "components/SoftSnackbar";
function Email() {
    let params = useParams();
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState(0);
    const [appData, setAppData] = useState({});
    const [saving, setSaving] = useState(false);
    const [verification, setVerification] = useState(0);
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("apps/" + params?.id, "GET", {}).then((res) => {
            setAppData(res?.data);
        });
    }, [params?.id, refresh]);

    useEffect(() => {
        if(!appData?.api_url) return;

        callFetch("get-application-settings", "GET", [], setError, appData?.api_url).then((res) => { 
            if(res.data.order_overview_verification == 1){
               setVerification(1);
            }else{
               setVerification(0);
            }
       });
    }, [appData?.api_url]) 

    const [successSB, setSuccessSB] = useState(false); 
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title={t('Success')}
            //content={t("Changes saved successfully")}
            dateTime={t("")}
            open={successSB}
            onClose={() => closeSuccessSB(false)}
            close={() => closeSuccessSB(false)}
            bgSuccess
        />
    );
  

    const changeStatus = (field) => {
        if(field == 'verification'){
            if(verification == 1){
                setVerification(0);
                setValue('verification_status', 0);
            }else{
                setVerification(1);
                setValue('verification_status', 1);
            }
        } 
    }

    const onSubmit = (formData) => {
        if(!appData?.api_url) return;

        formData.appKey = appData?.appKey;

        setSaving(true);
        callFetch("app/update-otp-status-settings", "POST", formData, setError, appData?.api_url).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            //setSubmitSuccess(true);
        });
    };

    return(
        <>
            <div style={{padding: '12px'}}>
                <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                    <div className="row g-3"> 
                        <div className="col-md-12">
                            <span className="font-size-14-rem font-weight-600">Verification Status </span>  &nbsp;&nbsp; {verification == '1' && <Switch defaultChecked onClick={() => changeStatus('verification')}/> } {verification != '1' && <Switch  onClick={() => changeStatus('verification')}/> }
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        {!saving && (
                            <button type="submit" className="btn btn-primary">
                                {t('Save')}
                            </button>
                        )}
                        {saving && (
                            <button type="submit" className="btn btn-disabled" disabled>
                                {t('Saving ...')}
                            </button>
                        )}
                    </div>
                </form>
            </div>
            {renderSuccessSB}
        </> 
    );
}

export default Email;
