/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState, React } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Setting pages components
import TableCell from "layouts/pages/account/settings/components/TableCell";

// Soft UI Dashboard PRO React components
import { useTranslation } from "react-i18next";
import callFetch from "helpers/callFetch"; 

function Notifications() {

  const [user, setUser] = useState([]);
  const { t } = useTranslation();  
  const [isUpdate, setIsUpdate] = useState(false);
  const [projectStatus, setProjectStatus] = useState('0');
  const [orderStatus, setOrderStatus] = useState('0');
  const [offerStatus, setOfferStatus] = useState('0');
  const [orderProcessingStatus, setOrderProcessingStatus] = useState('0');
  useEffect(() => {
    callFetch("get-notification-status", "GET", []).then((res) => {  
      setProjectStatus(res.user.alert_project_notification);
      setOrderStatus(res.user.alert_order_notification);
      setOfferStatus(res.user.alert_offer_notification);
      setOrderProcessingStatus(res.user.alert_status_orderprocessing_notification); 
    });
  }, []); 

  const updateStatus = (type) => {
    if(type == 'project'){
      if(projectStatus == '1'){
        setProjectStatus('0');
      }else{
        setProjectStatus('1');
      }
    } 

    if(type == 'order'){
      if(projectStatus == '1'){
        setOrderStatus('0');
      }else{
        setOrderStatus('1');
      }
    } 

    if(type == 'offer'){
      if(projectStatus == '1'){
        setOfferStatus('0');
      }else{
        setOfferStatus('1');
      }
    } 

    if(type == 'status_order_processing'){
      if(projectStatus == '1'){
        setOrderProcessingStatus('0');
      }else{
        setOrderProcessingStatus('1');
      }
    } 
    callFetch("update-notification-status/"+type, "GET", []).then((res) => { 
    });
  };

  return (
    <Card id="notifications">
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">{t('Notifications')}</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Choose how you receive notifications. These notification settings apply to the things
          you’re watching.
        </SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <SoftBox>
          <Table sx={{ minWidth: "36rem" }}>
            <SoftBox component="thead">
              <TableRow>
                <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                  Activity
                </TableCell>
                <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                   <span style={{position: 'relative', right: '22px'}}>Status</span>
                </TableCell> 
              </TableRow>
            </SoftBox>
            <TableBody>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography display="block" variant="button" fontWeight="regular">
                    {t('New Project')} 
                    </SoftTypography>
                    <SoftTypography variant="caption" color="text" fontWeight="regular">
                      Notify when another user mentions you in a comment
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                <div className="form-check form-switch">
                   {projectStatus != '0' && (
                      <input className="form-check-input" checked type="checkbox" onClick={() => updateStatus('project')}/>
                   )}
                   {projectStatus == '0' && (
                      <input className="form-check-input" type="checkbox" onClick={() => updateStatus('project')}/>
                   )}          
                </div>
                </TableCell> 
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography display="block" variant="button" fontWeight="regular">
                    {t('New Orders')} 
                    </SoftTypography>
                    <SoftTypography variant="caption" color="text" fontWeight="regular">
                      Notify when another user comments your item.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <div className="form-check form-switch">
                    {orderStatus != '0' && (
                        <input className="form-check-input" checked type="checkbox" onClick={() => updateStatus('order')}/>
                    )}
                    {orderStatus == '0' && (
                        <input className="form-check-input" type="checkbox" onClick={() => updateStatus('order')}/>
                    )}    
                  </div> 
                </TableCell> 
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography display="block" variant="button" fontWeight="regular">
                    {t('New Offer')} 
                    </SoftTypography>
                    <SoftTypography variant="caption" color="text" fontWeight="regular">
                      Notify when another user follows you.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                <div className="form-check form-switch">
                  {offerStatus != '0' && (
                      <input className="form-check-input" checked type="checkbox" onClick={() => updateStatus('offer')}/>
                   )}
                   {offerStatus == '0' && (
                      <input className="form-check-input" type="checkbox" onClick={() => updateStatus('offer')}/>
                   )}               
                </div>  
                </TableCell> 
              </TableRow>
            
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <SoftBox lineHeight={1.4}>
                    <SoftTypography display="block" variant="button" fontWeight="regular">
                    {t('New Status / Order Processing')} 
                    </SoftTypography>
                    <SoftTypography variant="caption" color="text" fontWeight="regular">
                      Notify when another user follows you.
                    </SoftTypography>
                  </SoftBox>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                <div className="form-check form-switch">
                  {orderProcessingStatus != '0' && (
                      <input className="form-check-input" checked type="checkbox" onClick={() => updateStatus('status_order_processing')}/>
                   )}
                   {orderProcessingStatus == '0' && (
                      <input className="form-check-input" type="checkbox" onClick={() => updateStatus('status_order_processing')}/>
                   )}   
                </div> 
                </TableCell> 
              </TableRow> 
            </TableBody>
          </Table>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default Notifications;