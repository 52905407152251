import { React, useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IndexTable from "./IndexTable";
import Cookies from "js-cookie";
import SoftSnackbar from "components/SoftSnackbar";

import Create from './Create';

function Index() {
    let params = useParams();
    const { t } = useTranslation();
    let { status } = useParams();

    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
            color="success"
            icon="check"
            title="Success"
            content="Changes saved successfully"
            dateTime="Just Now"
            open={successSB}
            onClose={closeSuccessSB}
            close={closeSuccessSB}
            bgWhite
        />
    );

    useEffect(() => {
        console.log(status);
        if (status == "all") {
            openSuccessSB();
        }
    }, []);

    return (
        <>
            <div className="d-sm-flex justify-content-between">
                <div className="relative-h">
                    {Cookies.get("permissions").indexOf("EC") !== -1 ? (
                        <button
                            type="button" 
                            data-bs-toggle="modal"
                            data-bs-target="#create-superadmin"
                            className="btn btn-icon btn-primary"
                        >
                            {t("Add Superadmin")}
                        </button>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t("All Super Admin")}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2 projects-menu">
                            <IndexTable />
                        </div>
                    </div>
                </div>
                {renderSuccessSB}
            </div>

            <Create />
        </>
    );
}

export default Index;
