import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { callFetch } from "helpers/callFetch";
import { useTranslation } from 'react-i18next';
import SoftBox from "components/SoftBox";
import { Grid } from "@mui/material";
// Settings page components
import Sidenav from "./components/Sidenav";
import Header from "./components/Header";
import BasicInfo from "./components/BasicInfo";
import MonthlySalary from "./components/MontlySalary/MonthlySalary";
import DeviceManagment from "./components/DeviceManagemt/DeviceManagment";
import StartDateExitDate from "./components/StartDateExitDate/StartDateExitDate";

const EmployeeEdit = () => {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation()
  const [designations, setDesignations] = useState([]);
  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [saving, setSaving] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const [componentShwo, setComponentShwo] = useState("Kontaktdaten")
  const [employee, setEmployee] = useState({});
  const [showData, setShowData] = useState({
    name: '---',
    role: '---',
    department_id: '---',
  });

  const [appData, setAppData] = useState({});
    useEffect(() => {
        callFetch("apps/" + params?.id, "GET", {}).then((res) => {
            setAppData(res?.data);
        });
    }, [params?.id, refresh]);

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm();
  const handleImageSelect = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
  };

  const handleRefresh = () => {
    setRefresh(refresh + 1)
  }
  const handleNameChange = (name) => {
    setShowData(name)
  };
  useEffect(() => {
    if(!appData?.api_url) return;

    //callFetch("employees/" + params.id + "/edit"+'&appKey='+appData?.appKey, "GET", [], {}, appData?.api_url).then((res) => {
    callFetch("app/employees/" + params?.user_id + "/edit"+'?appKey='+appData?.appKey, "GET", [],  {}, appData?.api_url).then((res) => {
      setEmployee(res.data);
    });

  }, [refresh, params?.user_id, location?.hash]);

  const onSubmit = (formData) => {
    if (!appData?.api_url) return;

    setSaving(true);
    callFetch("employees", "POST", formData, setError).then((res) => {
      setSaving(false);
      if (!res.ok) return;
      setSubmitSuccess(true);
    });
  };

  const componentSwitch = (value) => {
    setComponentShwo(value)
  }
  return (
    <SoftBox mt={4}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={3}>
          <Sidenav switchComponent={componentSwitch} />
        </Grid>
        <Grid item xs={12} lg={9}>
          <SoftBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Header imageUrl={selectedImageUrl} data={showData} />
              </Grid>
              <Grid item xs={12}>
                {
                  location?.hash === "#kontaktdaten" && <BasicInfo onImageSelect={handleImageSelect} nameChangeValue={handleNameChange} showData={showData} />
                }

                {
                  !location?.hash && componentShwo === "Kontaktdaten" && <BasicInfo onImageSelect={handleImageSelect} nameChangeValue={handleNameChange} showData={showData} />
                }
                {
                  location?.hash === "#monatiches-gehalt" && <MonthlySalary refreshData={handleRefresh} employee={employee} title={componentShwo} />
                }
                {
                  location?.hash === "#gerateverwaltung" && <DeviceManagment employee={employee} title={componentShwo} refreshData={handleRefresh} />
                }
                {
                  location?.hash === "#beginndatum-austrittsdatum" && <StartDateExitDate refreshData={handleRefresh} employee={employee} title={componentShwo} />
                }
              </Grid>

              {/* <Grid item xs={12}>
              <ChangePassword /> 
            </Grid>
            <Grid item xs={12}>
              <Authentication />
            </Grid>
            <Grid item xs={12}>
              <Accounts />
            </Grid>
            <Grid item xs={12}>
              <Notifications />
            </Grid>
            <Grid item xs={12}>
              <Sessions />
            </Grid>
            <Grid item xs={12}>
              <DeleteAccount />
            </Grid> */}
            </Grid>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  )
}

export default EmployeeEdit
