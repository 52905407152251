import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "helpers/callFetch";
import { Switch } from "@mui/material";

function Create(props) {
    const { t } = useTranslation();
    const params = useParams();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [appData, setAppData] = useState({});

    useEffect(() => {
        callFetch("apps/" + params?.id, "GET", {}).then((res) => {
            setAppData(res?.data);
        });
    }, [params?.id, refresh]);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();
    
    /*
    useEffect(() => {
        if(!appData?.api_url) return;

        if (params?.user_id && refresh2) {
            callFetch("app/employees/" + params?.user_id + "/edit"+'?appKey='+appData?.appKey, "GET", [],  {}, appData?.api_url).then((res) => {
                setValue('name', res?.data?.name);
                setValue('email', res?.data?.email);
                setValue('mobile', res?.data?.mobile);
                setValue('role', res?.data?.role);
                setValue('gender', res?.data?.gender);
                setValue('department_id', res?.data?.department_id);
                setValue('joining_date', res?.data?.joining_date);
                setValue('date_of_birth', res?.data?.date_of_birth);
                setValue('address', res?.data?.address);
            });
        }

    }, [refresh2, params?.user_id, appData?.api_url]);
    */


    const onSubmit = (formData) => {
        if(!appData?.api_url) return;
        
        formData.appKey = appData?.appKey;

        setSaving(true);
        callFetch("app/super-admin", "POST", formData, setError, appData?.api_url).then((res) => {
            setSaving(false);

            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return (
        <div className="modal fade" id="create-superadmin" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="departmentModalLabel">{t('Super Admin')}</h5>
                        <button type="button" className="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">

                            <div className="row g-3">
                                
                                <div className="col-md-6">
                                    <label>
                                        {t('Name')} *
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('eg. Jhon Doe')}
                                        {...register("name", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                </div>
                                
                                <div className="col-md-6">
                                    <label>
                                        {t('Email')} *
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control mb-4"
                                        placeholder={t('eg. email@mail.com')}
                                        {...register("email", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                </div>
                            </div>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <label>
                                        {t('Password')} *
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control mb-4"
                                        placeholder={t('eg.') + " ********"}
                                        {...register("password", {
                                            required: true,
                                        })}
                                        required={true}
                                    />
                                    <div className="invalid-feedback">{errors.password && errors.password.message}</div>
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        {t('Mobile')}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control mb-4"
                                        placeholder={t('eg. 98765432')}
                                        {...register("mobile")} />
                                    <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                                </div>
                            </div>

                            <div className="row g-3">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t('Gender')}
                                        </label>
                                        <select
                                            className="form-control"
                                            {...register("gender")}>
                                            <option value="">--</option>
                                            <option value="Male">{t('Male')}</option>
                                            <option value="Female">{t('Female')}</option>
                                            <option value="Other">{t('Others')}</option>
                                        </select>
                                        <div className="invalid-feedback">{errors.gender && errors.gender.message}</div>
                                    </div>
                                </div>
                                
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t('Date of Birth')}
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control mb-4 flatpickr"
                                            placeholder={t('eg. 16-04-2022')}
                                            {...register("date_of_birth")} />
                                        <div className="invalid-feedback">{errors.date_of_birth && errors.date_of_birth.message}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3">
                                <div className="form-group mb-4">
                                    <label>
                                        {t('Address')}
                                    </label>
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        placeholder={t('eg. 1234 My street, City')}
                                        {...register("address")}></textarea>
                                    <div className="invalid-feedback">{errors.address && errors.address.message}</div>
                                </div>
                            </div>
                            <div className="row g-3">
                                <div className="col-12 mt-4">
                                    {!saving && (
                                        <button type="submit" className="btn btn-primary">
                                            {t('Save')}
                                        </button>
                                    )}
                                    {saving && (
                                        <button type="submit" className="btn btn-disabled" disabled>
                                            {t('Saving')} ...
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Create;
