import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { callFetch } from "helpers/callFetch";
import { useTranslation } from 'react-i18next';
import SoftBox from "components/SoftBox";
import { Grid } from "@mui/material";
// Settings page components
import Sidenav from "./components/Sidenav";
import Header from "./components/Header";
import BasicInfo from "./components/BasicInfo";
import MonthlySalary from "./components/MontlySalary/MonthlySalary";
import DeviceManagment from "./components/DeviceManagemt/DeviceManagment";
import StartDateExitDate from "./components/StartDateExitDate/StartDateExitDate";


function EmployeeCreate() {
  const { t } = useTranslation();
  const location = useLocation()
  let params = useParams();
  //const [designations, setDesignations] = useState([]);
  //const [roles, setRoles] = useState([]);
  //const [departments, setDepartments] = useState([]);
  const [saving, setSaving] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const [componentShwo, setComponentShwo] = useState("Kontaktdaten")
  const [showData, setShowData] = useState({
    name: '---',
    role: '---',
    department_id: '---',
  });
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm();
  const handleImageSelect = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
  };

  const handleNameChange = (name) => {
    setShowData(name)
  };

  const componentSwitch = (value) => {
    setComponentShwo(value)
  }

  return submitSuccess ? <Navigate to={'/apps/'+params?.id+'/user_setup'} /> :
    <SoftBox mt={4}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={3}>
          <Sidenav switchComponent={componentSwitch} />
        </Grid>
        <Grid item xs={12} lg={9}>
          <SoftBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Header imageUrl={selectedImageUrl} data={showData} />
              </Grid>
              <Grid item xs={12}>
                {
                  location?.hash === "#kontaktdaten" && <BasicInfo onImageSelect={handleImageSelect} nameChangeValue={handleNameChange} showData={showData} />
                }

                {
                  !location?.hash && componentShwo === "Kontaktdaten" && <BasicInfo onImageSelect={handleImageSelect} nameChangeValue={handleNameChange} showData={showData} title={componentShwo} />
                }
                {
                  location?.hash === "#monatiches-gehalt" && <MonthlySalary onImageSelect={handleImageSelect} nameChangeValue={handleNameChange} showData={showData} title={componentShwo} />
                }
                {
                  location?.hash === "#gerateverwaltung" && <DeviceManagment title={componentShwo} />
                }
                {
                  location?.hash === "#beginndatum-austrittsdatum" && <StartDateExitDate title={componentShwo} />
                }
              </Grid>
            </Grid>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
}

export default EmployeeCreate;
