import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import callFetch from "helpers/callFetch";
import { Grid, Switch } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSnackbar from "components/SoftSnackbar";

function StartDateExitDate({ employee, title,refreshData }) {
    const params = useParams();
    const { t } = useTranslation();
    const [roles, setRoles] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [visible, setVisible] = useState(true);
    const [receivedError, setReceivedError] = useState(null);

    const [appData, setAppData] = useState({});
    useEffect(() => {
        callFetch("apps/" + params?.id, "GET", {}).then((res) => {
            setAppData(res?.data);
        });
    }, [params?.id, refresh]);
    
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    const handleSetVisible = () => setVisible(!visible);


    useEffect(() => {
        if(employee){
            setValue('start_date', employee?.start_date);
            setValue('end_date', (employee?.end_date == null || employee?.end_date == 'null' ? "" : employee?.end_date ));
            if(employee?.status === "aktivieren"){
                setVisible(true)
            }else if(employee?.status === "deaktivieren"){
                setVisible(false)
            }
        }
    }, [refresh,employee]);

    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const renderSuccessSB = (
        <SoftSnackbar
          color="success"
          icon="check"
          title="Success"
          content="Beginndatum/Austrittsdatum Successfully Updated"
          dateTime="Just Now"
          open={successSB}
          onClose={closeSuccessSB}
          close={closeSuccessSB}
          bgWhite
        />
      );


    const onSubmit = (formData) => {
        if (!appData?.api_url) return;

        setSaving(true);
        formData.status = visible ? "aktivieren":"deaktivieren"
        formData.employee_identity_number = params?.user_id
        formData.name = employee?.name
        formData.email = employee?.email
        formData.appKey = appData?.appKey

        callFetch("app/employees/"+ params?.user_id , "POST", formData, setError, appData?.api_url).then((res) => {
            setSaving(false);
            if(receivedError == null && res.message === 'success' && params?.user_id){
                openSuccessSB();
            }
            refreshData();
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

return(
    <div className="row">
            <div className="col-12">
                <div className="card mb-4">
                    <div className="card-header pb-0">
                        <h6>{t(`${title}`)}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <input type="hidden" defaultValue="PUT" {...register("_method")} />
                           
                            <div className="row g-3">
                            <Grid item xs={12} md={6} lg={3} sx={{ mb: "5px" }}>
                                <SoftBox
                                    display="flex"
                                    alignItems="center"
                                    lineHeight={1}
                                >
                                <SoftTypography variant="caption" fontWeight="regular">
                                    {t('Mitarbeiter')} {visible ? "deaktivieren":"aktivieren"}
                                </SoftTypography>
                                <SoftBox mx={1}>
                                    <Switch checked={visible} onChange={handleSetVisible} />
                                </SoftBox>
                                </SoftBox>
                            </Grid>
                            
                            </div>
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t('Joining Date')} *
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control mb-4 flatpickr"
                                            placeholder={t('eg. 16-04-2022')}
                                            {...register("start_date", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.joining_date && errors.joining_date.message}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>
                                            {t('End Date')}
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control mb-4 flatpickr"
                                            placeholder={t('eg. 16-04-2022')}
                                            {...register("end_date")} />
                                        <div className="invalid-feedback">{errors.date_of_birth && errors.date_of_birth.message}</div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-12 mb-4 text-end">
                                {!saving && (
                                    <button type="submit" className="btn btn-primary">
                                        {t('Save')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled" disabled>
                                        {t('Saving ...')}
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            {renderSuccessSB}
        </div>
)
}

export default StartDateExitDate