import { React, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import Cookies from 'js-cookie';
import callFetch from "helpers/callFetch";
import SoftAlert from "components/SoftAlert";

function Config() {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [data, setData] = useState({});
    const [appData, setAppData] = useState({});
    const [readonlyFields, setReadonlyFields] = useState([
        'APP_NAME',
        'APP_KEY',
        'APP_URL',
        'MAIL_MAILER',
        'MAIL_HOST',
        'MAIL_PORT',
        'MAIL_USERNAME',
        'MAIL_PASSWORD',
        'MAIL_ENCRYPTION',
        'MAIL_FROM_ADDRESS',
        'MAIL_FROM_NAME',
    ]);

    const {
        register,
        reset,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("apps/" + params?.id, "GET", {}).then((res) => {
            setAppData(res?.data);
        });
    }, [params?.id, refresh]);

    useEffect(() => {

        if (!appData?.api_url) return;

        callFetch("app/env" + '?appKey=' + appData?.appKey, "GET", [], {}, appData?.api_url).then((res) => {
            setData(res?.data);
            for (let key in res?.data) {
                setValue(key, res?.data[key])
            }
        });

    }, [appData?.api_url]);

    const onSubmit = (formData) => {
        if (!appData?.api_url) return;
       // console.log(formData)
        
        setSaving(true);
        //setData({});

       // formData.id = params.id;
        //formData.install_step = 'database';

        callFetch("app/env"+ '?appKey=' + appData?.appKey, "POST", formData, setError, appData?.api_url).then((res) => {
            setSaving(false);
            setSubmitSuccess(res?.message);


            //try again
            /*
            if (res?.data === 'Could not connect to the database. Try again!') {
                if (!res.ok) return;

                callFetch("apps/install", "POST", formData, setError).then((res) => {
                    setSaving(false);
                    if (!res.ok) return;
                    setData(res);
                    setRefresh(refresh + 1);
                });
            } else {
                setSaving(false);
                if (!res.ok) return;
                setData(res);
                setRefresh(refresh + 1);
            }
            */
        });
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('Config Setup')}</h6>
                        </div>
                        <div className="card-body">
                            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                <div className="row col-md-6">

                                    {Object.entries(data).map(([key, value]) => (
                                        <div className="col-md-12 mb-3">
                                            <label>{key}</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                {...register(key, {
                                                    required: false,
                                                })}
                                                readOnly={readonlyFields.indexOf(key) !== -1 ? true : false}
                                            />
                                            <div className="invalid-feedback">{errors[key] && errors[key].message}</div>
                                        </div>
                                    ))}

                                    <div className="col-12 mt-2">

                                        {submitSuccess === 'success' ? (
                                            <SoftAlert color="success" dismissible>
                                                {t('Updated successful')}
                                            </SoftAlert>
                                        ) : submitSuccess === 'error' ? (
                                            <SoftAlert color="error" dismissible>
                                                {t('Updated failed')}
                                            </SoftAlert>
                                        ) : ''}

                                        {!saving && (
                                            <button type="submit" className="btn btn-primary">
                                                {t('Save')}
                                            </button>
                                        )}
                                        {saving && (
                                            <button type="submit" className="btn btn-disabled" disabled>
                                                {t('Saving')} ...
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Config;
