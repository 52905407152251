import { React, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import callFetch from "helpers/callFetch";
import Setting from "examples/Icons/Setting";
import { number } from "prop-types";
import SoftTypography from "components/SoftTypography";
import Divider from "@mui/material/Divider";
import SoftBox from "components/SoftBox";
import SidenavCard from "components/SidenavCard";

function Sidebar() {
    const { t } = useTranslation();
    const [nowDrop, setNowDrop] = useState(window.location.href.split('/')[3]);
    let navClassName = "nav-link";
    let activeClassName = "nav-link active";
    let dropdownClass = "collapse";
    let dropdownClassShow = "collapse show";

    useEffect(() => {
        const g_sidenav_pinned = document.getElementsByClassName("g-sidenav-pinned");
        if (g_sidenav_pinned.length) {
            document.getElementById("iconSidenav").click();
        }
    }, [window.location.href]);

    return (
        <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3  bg-white" id="sidenav-main">
            <div className="sidenav-header">
                <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav" />
                <a
                    className="navbar-brand m-0"
                    href="/"
                    style={{
                        height: '100%',
                        textAlign: 'center',
                        lineHeight: '75px'
                    }}
                >
                    <img src="/assets/img/solacloud_logo.png" className="navbar-brand-img h-100" style={{ maxHeight: '20px' }} alt="main_logo" />
                </a>
            </div>
            <hr className="horizontal dark mt-0" />
            <div className="collapse navbar-collapse w-auto" id="sidenav-collapse-main">
                <ul className="navbar-nav">

                    <li className="nav-item">
                        <NavLink to="/dashboard" onClick={(e) => setNowDrop('dashboard')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                            <i className="fa-solid fa-dashboard icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                            <span className="sidenav-normal">{t('Dashboard')}</span>
                        </NavLink>
                    </li>

                    {JSON.parse(Cookies.get('permissions')).indexOf("appR") !== -1 ? (
                        <>
                            <Divider />
                            <SoftTypography
                                display="block"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="uppercase"
                                opacity={0.6}
                                pl={4}
                                mt={0}
                                mb={1}
                                ml={1}
                            >
                                {t('Apps')}
                            </SoftTypography>

                            <li className={"nav-item"}>
                                <a data-bs-toggle="collapse" href="#apps" className={nowDrop === 'apps' ? activeClassName : navClassName} aria-controls="apps" role="button" aria-expanded={nowDrop === 'apps'}>
                                    <i className="fa-solid fa-users icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                    <span className="nav-link-text ms-1">{t('Apps')}</span>
                                </a>
                                <div className={nowDrop === 'apps' ? dropdownClassShow : dropdownClass} id="apps">
                                    <ul className="nav ms-4 ps-3">
                                        <li className="nav-item">
                                            <NavLink to="/apps" onClick={(e) => setNowDrop('apps')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                <span className="sidenav-normal">{t('All Apps')}</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </>
                    ) : <></>}

                    {JSON.parse(Cookies.get('permissions')).indexOf("ER") !== -1 ? (
                        <>
                            <Divider />
                            <SoftTypography
                                display="block"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="uppercase"
                                opacity={0.6}
                                pl={4}
                                mt={0}
                                mb={1}
                                ml={1}
                            >
                                {t('Personalwesen')}
                            </SoftTypography>
                        </>
                    ) : <></>}

                    {JSON.parse(Cookies.get('permissions')).indexOf("ER") !== -1 ? (
                        <>
                            <li className={"nav-item"}>
                                <a data-bs-toggle="collapse" href="#hr" className={nowDrop === 'hr' ? activeClassName : navClassName} aria-controls="hr" role="button" aria-expanded={nowDrop === 'hr'}>
                                    <i className="fa-solid fa-users icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                    <span className="nav-link-text ms-1">{t('Staff Management')}</span>
                                </a>
                                <div className={nowDrop === 'hr' ? dropdownClassShow : dropdownClass} id="hr">
                                    <ul className="nav ms-4 ps-3">
                                        {JSON.parse(Cookies.get('permissions')).indexOf("ER") !== -1 ? (
                                            <li className="nav-item">
                                                <NavLink to="/employees" onClick={(e) => setNowDrop('hr')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                    <span className="sidenav-normal">{t('Employees')}</span>
                                                </NavLink>
                                            </li>
                                        ) : <></>}
                                    </ul>
                                </div>
                            </li>
                        </>
                    ) : <></>}


                    {JSON.parse(Cookies.get('permissions')).indexOf("RR") !== -1 ? (
                        <>
                            <Divider />
                            <SoftTypography
                                display="block"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="uppercase"
                                opacity={0.6}
                                pl={4}
                                mt={0}
                                mb={1}
                                ml={1}
                            >
                                {t('Einstellungen')}
                            </SoftTypography>
                            <li className="nav-item">
                                <a data-bs-toggle="collapse" href="#user-settings" className={nowDrop === 'user-settings' ? activeClassName : navClassName} aria-controls="user-settings" role="button" aria-expanded={nowDrop === 'user-settings'}>
                                    <i className="fa-solid fa-gear icon icon-shape icon-sm shadow border-radius-md bg-white text-center d-flex align-items-center justify-content-center me-2"></i>
                                    <span className="nav-link-text ms-1">{t('Settings')}</span>
                                </a>
                                <div className={nowDrop === 'user-settings' ? dropdownClassShow : dropdownClass} id="user-settings">
                                    <ul className="nav ms-4 ps-3">
                                        {JSON.parse(Cookies.get('permissions')).indexOf("RR") !== -1 ? (
                                            <>
                                                <li className="nav-item">
                                                    <NavLink to="/roles" onClick={(e) => setNowDrop('user-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                        <span className="sidenav-normal">{t('Settings')}</span>
                                                    </NavLink>
                                                </li>

                                                <li className="nav-item">
                                                    <a data-bs-toggle="collapse" href="#email-settings" className={nowDrop === 'email-settings' ? activeClassName : navClassName} aria-controls="email-settings" role="button" aria-expanded={nowDrop === 'email-settings'}>
                                                        <span className="nav-link-text">{t('Email Setup')}</span>
                                                    </a>
                                                    <div className={nowDrop === 'email-settings' ? dropdownClassShow : dropdownClass} id="email-settings">
                                                        <ul className="nav ms-3 ps-1                                                                  ">
                                                            {JSON.parse(Cookies.get('permissions')).indexOf("RR") !== -1 ? (
                                                                <>
                                                                    <li className="nav-item">
                                                                        <NavLink to="/settings/smtp/1" onClick={(e) => setNowDrop('smptp-settings')} className={({ isActive }) => isActive ? activeClassName : navClassName}>
                                                                            <span className="sidenav-normal">{t('SMTP')}</span>
                                                                        </NavLink>
                                                                    </li>
                                                                </>
                                                            ) : <></>}
                                                        </ul>
                                                    </div>
                                                </li>
                                            </>
                                        ) : <></>}
                                    </ul>
                                </div>
                            </li>
                        </>
                    ) : <></>}
                </ul>
            </div>
        </aside>
    );
}

export default Sidebar;
