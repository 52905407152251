import { React, useState} from "react";
import { useTranslation } from 'react-i18next'; 

import SmtpIndex from "./smtp/SmtpIndex";
import Email from "./Email";
import OTP from "./OTP";
import Attachment from "./extraAttachment/Attachment";

function EmailSetting() {
    const { t } = useTranslation();
    return(
        <div className="row"> 
            <div className="col-sm-12">
                <ul class="nav nav-pills mb-3 email-settings" id="pills-tab" role="tablist" style={{background: 'none'}}>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="pills-home-tabe" data-bs-toggle="pill" data-bs-target="#pills-homee" type="button" role="tab" aria-controls="pills-homee" aria-selected="true">E-Mail</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="pills-profile-tabe" data-bs-toggle="pill" data-bs-target="#pills-profilee" type="button" role="tab" aria-controls="pills-profilee" aria-selected="false">SMTP Setup</button>
                    </li>
                    <li class="nav-item" role="presentation" style={{maxWidth: '250px'}}>
                        <button class="nav-link" id="pills-contact-tabe" data-bs-toggle="pill" data-bs-target="#pills-contactt" type="button" role="tab" aria-controls="pills-contactt" aria-selected="false">Verification Code</button>
                    </li> 
                </ul> 
            </div>
            <div className="col-12">
                <div className=""> 
                    <div className="">
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane show active fade" id="pills-homee" role="tabpanel" aria-labelledby="pills-home-tabe" tabindex="0">
                                <div className="card" style={{padding: '12px'}}>
                                    <div className="card-body  scroll-cs" style={{maxHeight: '530px', padding: '0px'}}>
                                        <h6 style={{padding: '5px 0px 0px 12px'}}>{t('Edit E-Mail Text')}</h6>
                                        <Email/>
                                    </div>
                                </div>

                                <Attachment/>
                            </div>
                            <div class="tab-pane fade" id="pills-profilee" role="tabpanel" aria-labelledby="pills-profile-tabe" tabindex="0">
                                <SmtpIndex/>
                            </div>
                            <div class="tab-pane fade" id="pills-contactt" role="tabpanel" aria-labelledby="pills-contact-tabe" tabindex="0">
                                <div className="card" style={{padding: '12px'}}>
                                    <div className="card-body scroll-cs" style={{height: '530px', padding: '0px'}}>
                                        <OTP/>
                                    </div>
                                </div> 
                            </div>
                        </div> 
                    </div>
                </div> 
            </div>
        </div>
    );
}

export default EmailSetting;
