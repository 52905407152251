import { useEffect, useState, React } from "react";
import { useParams } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React components
import { useTranslation } from "react-i18next";
import { callFetch } from "helpers/callFetch";
import { useForm } from "react-hook-form";
import SoftEditor from "components/SoftEditor";

function InvoiceOptions() {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [fullPercentInvoice, setFullPercentInvoice] = useState(false);
    const [numberOfInvoice, setNumberOfInvoice] = useState(0);
    const [appData, setAppData] = useState({});
    const [data, setData] = useState({});

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("apps/" + params?.id, "GET", {}).then((res) => {
            setAppData(res?.data);
        });
    }, [params?.id, refresh]);


    useEffect(() => {
        if (!appData?.api_url) return;

        callFetch("app/invoice-option" + '?appKey=' + appData?.appKey, "GET", {}, {}, appData?.api_url).then((res) => {
            if (res?.message === 'success') {
                setData(res.data)
                setFullPercentInvoice(res?.full_percent_invoice?.full_percent_invoice == 1 ? true : false)
                setNumberOfInvoice(res?.data.length);

                setValue("number_of_invoice", (res?.data.length ? res?.data.length : ''))
                setValue("full_percent_invoice", (res?.full_percent_invoice?.full_percent_invoice == 1 ? true : false))
                setValue("full_percent_invoice_description", (res?.full_percent_invoice?.full_percent_invoice_description && res?.full_percent_invoice?.full_percent_invoice_description != 'null' ? res?.full_percent_invoice?.full_percent_invoice_description : ''))

                for (let [key, value] of Object.entries(res.data)) {
                    setValue("percentage[" + (parseInt(key) + 1) + "]", value?.percentage)
                    setValue("after_action[" + (parseInt(key) + 1) + "]", value?.after_action)
                    setValue("description[" + (parseInt(key) + 1) + "]", value?.description)
                }
            }
        });
    }, [refresh, appData?.appKey]);

    const renderInvoiceFields = () => {
        const fields = [];
        for (let i = 0; i < numberOfInvoice; i++) {
            fields.push(
                <div className="row mb-3">
                    <div className="col-2 mt-0">
                        <label>{t('Invoices')} {i + 1}</label>
                    </div>
                    <div className="col-2">
                        <input
                            type="number"
                            className="form-control"
                            placeholder={t('Enter percentage')}
                            {...register("percentage[" + (i + 1) + "]", {
                                required: true,
                                min: 0,
                                max: 100
                            })}
                        />
                    </div>

                    <div className="col-2 mt-0">
                        <label>% {t('der Auftragssumme')}</label>
                    </div>

                    <div className="col-2 mt-0">
                        <label>{t('After')}</label>
                    </div>

                    <div className="col-3 mt-0">
                        <select
                            className="form-control"
                            placeholder={t('eg. 123')}
                            {...register("after_action[" + (i + 1) + "]", {
                                required: true,
                            })}
                        >
                            <option value={''}>--</option>
                            <option value={'DC_Abnahme_erfolgreich'}>{t('DC Abnahme erfolgreich')}</option>
                            <option value={'Zahleingang_100_or_AC_Freigeben'}>{t('Zahleingang 100 or AC Freigeben')}</option>
                            <option value={'After_AC_Abnahme_Succesfull'}>{t('After AC Abnahme succesfull')}</option>
                        </select>
                    </div>
                    <div className="col-2 mt-0">
                    </div>
                    <div className="col-9 mt-2">
                        
                        <SoftEditor value={getValues("description[" + (i + 1) + "]")} onChange={(value) => { setValue("description[" + (i + 1) + "]", value)}} />
                        {/*

                        <textarea
                            className="form-control"
                            placeholder={t('Description')}
                            {...register("description[" + (i + 1) + "]", {
                                required: false
                            })}
                            rows={5}
                        >
                        </textarea>

                        <input
                            type="text"
                            {...register("description[" + (i + 1) + "]", {
                                required: false
                            })}
                        />
                        */}
                    </div>
                </div>
            );
        }
        return fields;
    };


    const onSubmit = (formData) => {
        if (!appData?.api_url) return;

        setSaving(true);

        formData.full_percent_invoice = formData?.full_percent_invoice ? 1 : 0
        formData.percentage = JSON.stringify(formData.percentage)
        formData.after_action = JSON.stringify(formData.after_action)
        formData.description = JSON.stringify(formData.description)

        callFetch("app/invoice-option" + '?appKey=' + appData?.appKey, "POST", formData, {}, appData?.api_url).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setRefresh(refresh + 1)
        });
    };

    return (
        <>
            <Card>
                <SoftBox p={3} lineHeight={1}>
                    <SoftBox mb={1}>
                        <SoftTypography variant="h5">{t('InvoiceOptions')}</SoftTypography>
                    </SoftBox>
                </SoftBox>
                <SoftBox pb={3} px={3}>
                    <div className="card-body px-0 pt-0 pb-2 projects-menu">
                        <ul className="nav nav-tabs nav-underline tabs-res project-filter-tab mb-3" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className={"nav-link active"} data-bs-toggle="tab" data-bs-target="#ac-dc-tab-pane" type="button" role="tab" aria-controls="ac-dc-tab-pane" aria-selected="true" style={{ color: '#0048B1', fontSize: '0.875rem' }}>AC & DC</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#dc-tab-pane" type="button" role="tab" aria-controls="dc-tab-pane" aria-selected="false" style={{ color: '#0048B1', fontSize: '0.875rem' }}>DC</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#ac-tab-pane" type="button" role="tab" aria-controls="ac-tab-pane" aria-selected="false" style={{ color: '#0048B1', fontSize: '0.875rem' }}>AC</button>
                            </li>
                        </ul>
                    </div>

                    <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                        <div className="row mb-3">
                            <div className="col-3 col-md-2">
                                <label>
                                    {t('How Many Invoice')}
                                </label>
                                <select
                                    className="form-control mb-4"
                                    placeholder={t('eg. 123')}
                                    {...register("number_of_invoice", {
                                        required: true,
                                        onChange: (e) => {
                                            setNumberOfInvoice(e.target.value)
                                        }
                                    })}
                                >
                                    <option value={''}>--</option>
                                    <option value={'1'}>1</option>
                                    <option value={'2'}>2</option>
                                    <option value={'3'}>3</option>
                                </select>
                            </div>

                            <div className="col-4 col-md-3">
                                <div className="form-check pt-3 mt-md-4">
                                    <label>
                                        {t('Include 100% invoice')}
                                    </label>
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        {...register("full_percent_invoice", {
                                            required: false,
                                            onChange: (e) => {
                                                setFullPercentInvoice(e.target.checked)
                                            }
                                        })}
                                    />
                                </div>
                            </div>

                            {fullPercentInvoice ? (
                                <div className="col-5 col-md-6">
                                    <label>
                                        {t('Description')}
                                    </label>
                                    {/*
                                    <textarea
                                        className="form-control"
                                        placeholder={t('Description')}
                                        {...register("full_percent_invoice_description", {
                                            required: false,
                                        })}
                                        rows={5}
                                    >
                                    </textarea>
                                    */}

                                    <SoftEditor value={getValues("full_percent_invoice_description")} onChange={(value) => { setValue("full_percent_invoice_description", value)}} />
                                </div>
                            ) : ''}
                        </div>

                        {renderInvoiceFields()}

                        <div className="row">
                            <div className="col-12">
                                {!saving ? (
                                    <button type="submit" className="btn btn-primary">
                                        {t('Save')}
                                    </button>
                                ) : (
                                    <button type="submit" className="btn btn-disabled" disabled>
                                        {t('Saving ...')}
                                    </button>
                                )}
                            </div>
                        </div>
                    </form>
                </SoftBox>
            </Card>
        </>
    );
}

export default InvoiceOptions;