import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { Navigate } from "react-router-dom";
import callFetch from "helpers/callFetch";
import { Switch } from "@mui/material";

function Create(props) {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [status, setStatus] = useState(0);
    const [existingApp, setExistingApp] = useState(false);
    const [refresh, setRefresh] = useState(0);

    const {
        register,
        reset,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();

    const onSubmit = (formData) => {
        setSaving(true);
        formData.existing_app = existingApp ? 1 : 0;
        formData.status = status ? 1 : 0;

        callFetch("apps", "POST", formData, setError).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setSubmitSuccess(true);
        });
    };

    return submitSuccess ? <Navigate to='/apps' /> :
        <div className="row">
            <div className="col-md-9">
                <div className="card">
                    <div className="card-header pb-0">
                        <h6>{t('Add Apps')}</h6>
                    </div>
                    <div className="card-body">
                        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                            <div className="row g-3">
                                <div className="col-md-12">
                                    <label>{t('Companpy Name')}</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder={t('Companpy Name')}
                                        {...register("company_name", {
                                            required: true,
                                        })}
                                        required
                                    />
                                    <div className="invalid-feedback">{errors.Name && errors.Name.message}</div>
                                </div>
                                <div className="col-md-9">
                                    <label>{t('Address')}</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder={t('Address')}
                                        {...register("address")}
                                    />
                                    <div className="invalid-feedback">{errors.address && errors.address.message}</div>
                                </div>

                                <div className="col-md-3">
                                    <label>{t('Nr.')}</label>
                                    <input
                                        type="number"
                                        className="form-control form-control-sm"
                                        placeholder={t('Nr.')}
                                        {...register("nr")}
                                    />
                                    <div className="invalid-feedback">{errors.nr && errors.nr.message}</div>
                                </div>

                                <div className="col-md-6">
                                    <label>{t('Ort')}</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder={t('Ort')}
                                        {...register("ort")} />
                                    <div className="invalid-feedback">{errors.ort && errors.ort.message}</div>
                                </div>

                                <div className="col-md-6">
                                    <label>{t('PLZ')}</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder={t('PLZ')}
                                        {...register("plz")} />
                                    <div className="invalid-feedback">{errors.plz && errors.plz.message}</div>
                                </div>


                                <div className="col-md-6">
                                    <label>{t('Telephone Phone')}</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder={t('Telephone Phone')}
                                        {...register("telephone")} />
                                    <div className="invalid-feedback">{errors.telephone && errors.telephone.message}</div>
                                </div>

                                <div className="col-md-6">
                                    <label>{t('Mobile Phone')}</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder={t('Mobile Phone')}
                                        {...register("office_phone")} />
                                    <div className="invalid-feedback">{errors.office_phone && errors.office_phone.message}</div>
                                </div>

                                <div className="col-md-6">
                                    <label>{t('Fax')}</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder={t('Fax')}
                                        {...register("fax")} />
                                    <div className="invalid-feedback">{errors.fax && errors.fax.message}</div>
                                </div>

                                <div className="col-md-6">
                                    <label>{t('E-mail')}</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder={t('E-mail')}
                                        {...register("email")} />
                                    <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                </div>

                                <div className="col-md-6">
                                    <label>{t('Status')}</label> &nbsp;
                                    <Switch
                                        checked={status ? true : false}
                                        onChange={(val) => {
                                            if (status) {
                                                setStatus(0);
                                            } else {
                                                setStatus(1);
                                            }
                                        }}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <div class="form-check">
                                        <input
                                            onClick={(e) => {
                                                setExistingApp(e.target.checked);
                                            }}
                                            class="form-check-input"
                                            type="checkbox"
                                        />
                                        <label class="form-check-label">{t('Existing app ?')}</label>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <label>{t('Frontend Url')}</label>
                                    <input
                                        type="url"
                                        className="form-control form-control-sm"
                                        placeholder={t('Frontend Url')}
                                        {...register("frontend_url")} />
                                    <div className="invalid-feedback">{errors.frontend_url && errors.frontend_url.message}</div>
                                </div>

                                {existingApp ? (
                                    <>
                                        <div className="col-md-12">
                                            <label>{t('Api Url')}</label>
                                            <input
                                                type="url"
                                                className="form-control form-control-sm"
                                                placeholder={t('Api Url')}
                                                {...register("api_url", {
                                                    required: true
                                                })}
                                                required
                                            />
                                            <div className="invalid-feedback">{errors.api_url && errors.api_url.message}</div>
                                        </div>

                                        <div className="col-md-12">
                                            <label>{t('Backend Url')}</label>
                                            <input
                                                type="url"
                                                className="form-control form-control-sm"
                                                placeholder={t('Backend Url')}
                                                {...register("backend_url", {
                                                    required: true
                                                })}
                                                required
                                            />
                                            <div className="invalid-feedback">{errors.backend_url && errors.backend_url.message}</div>
                                        </div>
                                    </>
                                ) : ''}
                            </div>
                            <div className="col-12 mt-4">
                                {!saving && (
                                    <button type="submit" className="btn btn-primary">
                                        {t('Save')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled" disabled>
                                        {t('Saving')} ...
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>;
}

export default Create;
