import { React, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import Cookies from 'js-cookie';
import callFetch from "helpers/callFetch";
import SoftAlert from "components/SoftAlert";

function Install() {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [data, setData] = useState({});
    const [appData, setAppData] = useState({});

    const {
        register,
        reset,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {

        callFetch("apps/" + params?.id, "GET", {}).then((res) => {
            setAppData(res?.data);
            setValue('DB_CONNECTION', res?.data?.DB_CONNECTION)
            setValue('DB_HOST', res?.data?.DB_HOST);
            setValue('DB_PORT', res?.data?.DB_PORT);
            setValue('DB_DATABASE', res?.data?.DB_DATABASE);
            setValue('DB_USERNAME', res?.data?.DB_USERNAME);
            setValue('DB_PASSWORD', (res?.data?.DB_PASSWORD && res?.data?.DB_PASSWORD != 'null' ? res?.data?.DB_PASSWORD : ''));
        });
        
    }, [params?.id, refresh]);

    const onSubmit = (formData) => {
        setSaving(true);
        setData({});

        formData.id = params.id;
        formData.install_step = 'database';

        callFetch("apps/install", "POST", formData, setError).then((res) => {
            //setSaving(false);
            //try again
            if(res?.data === 'Could not connect to the database. Try again!' || res?.data === '.env Update Successful'){
                if (!res.ok) return;
                setTimeout(()=> {
                    callFetch("apps/install", "POST", formData, setError).then((res) => {
                        setSaving(false);
                        if (!res.ok) return;
                        setData(res);
                        setRefresh(refresh + 1);
                    });
                }, 2000);
            }else{
                setSaving(false);
                if (!res.ok) return;
                setData(res);
                setRefresh(refresh + 1);
            }
        });
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('Database Setup')}</h6>
                        </div>
                        <div className="card-body">
                            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                <div className="row col-md-6">
                                    <div className="col-md-12">
                                        <label>{t('DB CONNECTION')}</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            {...register("DB_CONNECTION", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.DB_CONNECTION && errors.DB_CONNECTION.message}</div>
                                    </div>

                                    <div className="col-md-12">
                                        <label>{t('DB HOST')}</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            {...register("DB_HOST", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.DB_HOST && errors.DB_HOST.message}</div>
                                    </div>

                                    <div className="col-md-12">
                                        <label>{t('DB PORT')}</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            {...register("DB_PORT", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.DB_PORT && errors.DB_PORT.message}</div>
                                    </div>

                                    <div className="col-md-12">
                                        <label>{t('DB Name')}</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            {...register("DB_DATABASE", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.DB_DATABASE && errors.DB_DATABASE.message}</div>
                                    </div>

                                    <div className="col-md-12">
                                        <label>{t('DB Username')}</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            {...register("DB_USERNAME", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.DB_USERNAME && errors.DB_USERNAME.message}</div>
                                    </div>

                                    <div className="col-md-12">
                                        <label>{t('DB Password')}</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            {...register("DB_PASSWORD", {
                                                required: false,
                                            })}
                                        />
                                        <div className="invalid-feedback">{errors.DB_PASSWORD && errors.DB_PASSWORD.message}</div>
                                    </div>

                                    <div className="col-12 mt-4">

                                        {data?.message === 'success' ? (
                                            <SoftAlert color="success" dismissible>
                                                {data?.data}
                                            </SoftAlert>
                                        ) : data?.message === 'error' ? (
                                            <SoftAlert color="error" dismissible>
                                                {data?.data}
                                            </SoftAlert>
                                        ) : ''}

                                        {appData?.installed === 0 ? (
                                            <>
                                                {!saving && (
                                                    <button type="submit" className="btn btn-primary">
                                                        {t('Install')}
                                                    </button>
                                                )}
                                                {saving && (
                                                    <button type="submit" className="btn btn-disabled" disabled>
                                                        {t('Installing')} ...
                                                    </button>
                                                )}
                                            </>
                                        ) : ''}
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Install;
