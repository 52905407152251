import { React, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import callFetch from "../../helpers/callFetch";
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import deleteAlert from "../../helpers/deleteAlert";
import Cookies from 'js-cookie';
import { Switch } from "@mui/material";
import { width } from '@mui/system';

import UpdateCheck from './UpdateCheck'

function IndexTable(props) {
    const { t } = useTranslation();
    const [tabledata, setTableData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [updating, setUpdating] = useState('');
    const [availableVersion, setAvailableVersion] = useState('');
    const [systemInfo, setSystemInfo] = useState({});

    const statusUpdate = (id, status) => {
        var formData = {};
        formData.id = id;
        formData._method = 'PUT';
        formData.status = status ? 1 : 0;

        callFetch("apps/" + id, "POST", formData).then((res) => {
            if (!res.ok) return;
        });
    }
    
    /*
    const updateCheck = (data, availableversion) => {

        if (!data?.appKey) return;
        if (!availableversion) return;
        callFetch("app/get-system-info" + '?appKey=' + data?.appKey + '&availableVersion=' + availableversion, "GET", {}, {}, data?.api_url).then((res) => {

            if (!res?.data) return;

            var updateSystemInfo = systemInfo;
            updateSystemInfo[data?.id] = res?.data;
            setSystemInfo(updateSystemInfo);
            setRefresh2(refresh2 + 1)
        });
    }
    */


    const updateNow = (data) => {
        if (!data?.appKey) return;

        setUpdating(data?.id)
        callFetch("app/updateNow" + '?appKey=' + data?.appKey, "GET", {}, {}, data?.api_url).then((res) => {
            setUpdating('')
        });
    }

    const tableHeadings = [
        {
            name: t('ID'),
            selector: row => (
                <>
                    {row?.installed || (row?.api_url && row?.backend_url && row?.installed === 0) ? (
                        <NavLink to={'/apps/' + row.id + '/companyprofile'}>{row.id}</NavLink>
                    ) : row?.id}
                </>
            ),
            width: '80px',
            sortable: true,
        },
        {
            name: t('Company'),
            selector: row => (
                <>{row.company_name}</>
            ),
            sortable: true,
        },
        {
            name: t('Address'),
            selector: row => (
                <>{(row.address ? row.address : '') + ' ' + (row?.nr ? row?.nr : '') + ' ' + (row?.ort ? row?.ort : '') + ' ' + (row?.plz ? row?.plz : '')}</>
            ),
            sortable: true,
        },
        {
            name: t('Api Url'),
            selector: row => (
                <>{row.api_url}</>
            ),
            sortable: true,
            width: '400px',
        },
        {
            name: t('Frontend Url'),
            selector: row => (
                <><a target='_blank' href={row?.frontend_url}>{row?.frontend_url}</a></>
            ),
            sortable: true,
        },
        {
            name: t('Status'),
            selector: row => (
                <>
                {row?.existing_app == 0 ? (
                    <Switch
                        defaultChecked={row?.status ? true : false}
                        onChange={(e) => {
                            statusUpdate(row?.id, (e.target.checked ? 1 : 0));
                        }}
                    />
                ) : ''}
                    
                </>
            ),
            sortable: true,
        },
        /*
        {
            name: t('Version'),
            selector: row => (
                <>{row?.version}</>
            ),
            width: '100px',
            sortable: true,
        },
        */
        {
            name: t('Install'),
            selector: row => (
                <>
                    {row?.installed ? 'Successfull'
                        : row?.api_url && row?.backend_url && row?.installed === 0 ? (
                            <NavLink to={'/apps/' + row.id + '/install'} className='btn btn-primary btn-sm mb-0'>
                                {t('Install Now')}
                            </NavLink>
                        ) : 'Preparing for install...'}
                </>
            ),
            sortable: true,
            width: '170px',
        },
        {
            name: t('Version'),
            selector: row => (
                <>
                {row?.existing_app == 0 ? (
                <>
                    <UpdateCheck data={row} availableversion={availableVersion} />

                    {/*systemInfo[row?.id] && systemInfo[row?.id]?.updated == 1 ? (
                        <>{systemInfo[row?.id]?.current_version}</>
                    ) : systemInfo[row?.id] ? (
                        <>
                            {updating == row?.id ? 'Updating ...' : 'Update Available '+systemInfo[row?.id]?.available_version}
                        </>
                    ) : ''*/}
                </>
                ) : ''}
                </>
            ),
            width: '180px',
            sortable: true,
        },
        {
            name: t('Actions'),
            cell: row => (
                <>
                    {JSON.parse(Cookies.get('permissions')).indexOf("appU") !== -1 ||
                        JSON.parse(Cookies.get('permissions')).indexOf("appD") !== -1 ? (
                        <div className="text-center dropstart">
                            <a href="/" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-ellipsis-v text-xs"></i>
                            </a>
                            <ul className="dropdown-menu">
                                {JSON.parse(Cookies.get('permissions')).indexOf("appD") !== -1 ? (
                                    <li><a className="dropdown-item text-danger" href="#0" onClick={(e) => deleteAlert(e, 'apps', row.id, t).then(res => setRefresh(refresh + 1))}>{t('Delete')}</a></li>
                                ) : <></>}
                            </ul>
                        </div>
                    ) : <></>}
                </>
            )
        }
    ];

    useEffect(() => {
        callFetch("apps?page=" + pageNumber, "GET", []).then((res) => {
            setTableData(res.data);
            setAvailableVersion(res.available_version);
            
            /*
            for (let [key, row] of Object.entries(res?.data?.data)) {
                updateCheck(row, res.available_version)
            }
            */

        });
    }, [pageNumber, refresh, props?.refresh]);

    const handlePageChange = page => {
        setPageNumber(page);
    }

    function toPages(pages) {
        const results = [];

        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }

        return results;
    }

    // RDT exposes the following internal pagination properties
    const BootyPagination = ({
        onChangePage,
        currentPage
    }) => {
        const handleBackButtonClick = () => {
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = () => {
            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        const pages = tabledata.last_page;
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === tabledata.last_page;
        const previosDisabled = currentPage === 1;

        return (
            <>
                <br />
                <p className="float-md-start pt-2 text-secondary text-xs font-weight-bolder ms-3">{t('Showing')} {tabledata.from} {t('to')} {tabledata.to} {t('of')} {tabledata.total} {t('entries')}</p>
                <nav className="float-md-end me-2">
                    <ul className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleBackButtonClick}
                                disabled={previosDisabled}
                                aria-disabled={previosDisabled}
                                aria-label="previous page"
                            >
                                &#60;
                            </button>
                        </li>
                        {pageItems.map((page) => {
                            const className =
                                page === currentPage ? "page-item active" : "page-item";

                            return (
                                <li key={page} className={className}>
                                    <button
                                        className="page-link"
                                        onClick={handlePageNumber}
                                        value={page}
                                    >
                                        {page}
                                    </button>
                                </li>
                            );
                        })}
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={handleNextButtonClick}
                                disabled={nextDisabled}
                                aria-disabled={nextDisabled}
                                aria-label="next page"
                            >
                                &#62;
                            </button>
                        </li>
                    </ul>
                </nav>
                <div className="clearfix"></div>
            </>
        );
    };

    return <DataTable
        columns={tableHeadings}
        data={tabledata?.data}
        noDataComponent={t('There are no records to display')}
        className='data-table'
        pagination
        highlightOnHover
        paginationComponentOptions={{ noRowsPerPage: true }}
        paginationServer
        paginationTotalRows={tabledata?.total}
        onChangePage={handlePageChange}
        paginationComponent={BootyPagination}
    />;
}

export default IndexTable;
