import { React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import { callFetch } from "helpers/callFetch";
import { useTranslation } from 'react-i18next';
import Switch from "@mui/material/Switch";
import Cookies from 'js-cookie';
import SoftEditor from "components/SoftEditor";
import SmtpEdit from "pages/smtp/SmtpEdit";
function Email() {
    let params = useParams();
    const { t } = useTranslation();
    const [designations, setDesignations] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [roles, setRoles] = useState([]);
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [editDelay, setEditDelay] = useState(0);
    const [user, setUser] = useState([]);
    const [activeField, setActiveField] = useState('');
    const [description, setDescription] = useState('');
    const [appData, setAppData] = useState({});
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("apps/" + params?.id, "GET", {}).then((res) => {
            setAppData(res?.data);
        });
    }, [params?.id, refresh]);

    useEffect(() => {
        loadText('Angebot');
    }, [])

    const loadText = (v) => {
        if(!appData?.api_url) return;

        callFetch("app/smtp-email-configurations/" + 1 + "/edit"+ '?appKey=' + appData?.appKey, "GET", [], setError, appData?.api_url).then((res) => { 
            if(v == 'Angebot'){
                setActiveField('angebot')
                for (let [key, value] of Object.entries(JSON.parse(res?.data?.angebot))) {
                    setValue(key, (value == null || value == 'null' ? "" : value ));
                    if(key == 'description'){
                        setDescription((value == null || value == 'null' ? "" : value ))
                    }
                }
            }else if(v == 'Order'){
                setActiveField('order')
                for (let [key, value] of Object.entries(JSON.parse(res?.data?.order))) {
                    setValue(key, (value == null || value == 'null' ? "" : value ));
                    if(key == 'description'){
                        setDescription((value == null || value == 'null' ? "" : value ))
                    }
                }
            }
            else if(v == 'AC Planung'){
                setActiveField('ac_planning')
                for (let [key, value] of Object.entries(JSON.parse(res?.data?.ac_planning))) {
                    setValue(key, (value == null || value == 'null' ? "" : value ));
                    if(key == 'description'){
                        setDescription((value == null || value == 'null' ? "" : value ))
                    }
                }
            }
            else if(v == 'DC Planung'){
                setActiveField('dc_planning')
                for (let [key, value] of Object.entries(JSON.parse(res?.data?.dc_planning))) {
                    setValue(key, (value == null || value == 'null' ? "" : value ));
                    if(key == 'description'){
                        setDescription((value == null || value == 'null' ? "" : value ))
                    }
                }
            }
            else if(v == 'DC Zurückversetzen'){
                setActiveField('dc_zuruckversetzen')
                for (let [key, value] of Object.entries(JSON.parse(res?.data?.dc_zuruckversetzen))) {
                    setValue(key, (value == null || value == 'null' ? "" : value ));
                    if(key == 'description'){
                        setDescription((value == null || value == 'null' ? "" : value ))
                    }
                }
            }
            else if(v == 'AC Zurückversetzen'){
                setActiveField('ac_zuruckversetzen')
                for (let [key, value] of Object.entries(JSON.parse(res?.data?.ac_zuruckversetzen))) {
                    setValue(key, (value == null || value == 'null' ? "" : value ));
                    if(key == 'description'){
                        setDescription((value == null || value == 'null' ? "" : value ))
                    }
                }
            }
            else if(v == '70 Invoice'){
                setActiveField('seventy_percent_invoice')
                for (let [key, value] of Object.entries(JSON.parse(res?.data?.seventy_percent_invoice))) {
                    setValue(key, (value == null || value == 'null' ? "" : value ));
                    if(key == 'description'){
                        setDescription((value == null || value == 'null' ? "" : value ))
                    }
                }
            }else if(v == '30 Invoice'){
                setActiveField('thirty_percent_invoice')
                for (let [key, value] of Object.entries(JSON.parse(res?.data?.thirty_percent_invoice))) {
                    setValue(key, (value == null || value == 'null' ? "" : value ));
                    if(key == 'description'){
                        setDescription((value == null || value == 'null' ? "" : value ))
                    }
                }
            }else if(v == '70 Payment'){
                setActiveField('seventy_percent_payment')
                for (let [key, value] of Object.entries(JSON.parse(res?.data?.seventy_percent_payment))) {
                    setValue(key, (value == null || value == 'null' ? "" : value ));
                    if(key == 'description'){
                        setDescription((value == null || value == 'null' ? "" : value ))
                    }
                }
            }else if(v == '30 Payment'){
                setActiveField('thirty_percent_payment')
                for (let [key, value] of Object.entries(JSON.parse(res?.data?.thirty_percent_payment))) {
                    setValue(key, (value == null || value == 'null' ? "" : value ));
                    if(key == 'description'){
                        setDescription((value == null || value == 'null' ? "" : value ))
                    }
                }
            }else if(v == 'Liefertermin Module'){
                setActiveField('liefertermin_module')
                for (let [key, value] of Object.entries(JSON.parse(res?.data?.liefertermin_module))) {
                    setValue(key, (value == null || value == 'null' ? "" : value ));
                    if(key == 'description'){
                        setDescription((value == null || value == 'null' ? "" : value ))
                    }
                }
            }else if(v == 'Liefertermin Speicher'){
                setActiveField('liefertermin_speicher')
                for (let [key, value] of Object.entries(JSON.parse(res?.data?.liefertermin_speicher))) {
                    setValue(key, (value == null || value == 'null' ? "" : value ));
                    if(key == 'description'){
                        setDescription((value == null || value == 'null' ? "" : value ))
                    }
                }
            }else if(v == 'Liefertermin Großhändler'){
                setActiveField('liefertermin_grobhandler')
                for (let [key, value] of Object.entries(JSON.parse(res?.data?.liefertermin_grobhandler))) {
                    setValue(key, (value == null || value == 'null' ? "" : value ));
                    if(key == 'description'){
                        setDescription((value == null || value == 'null' ? "" : value ))
                    }
                }
            }else if(v == 'DC Abnahme'){
                setActiveField('dc_abnahme')
                for (let [key, value] of Object.entries(JSON.parse(res?.data?.dc_abnahme))) {
                    setValue(key, (value == null || value == 'null' ? "" : value ));
                    if(key == 'description'){
                        setDescription((value == null || value == 'null' ? "" : value ))
                    }
                }
            }else if(v == 'AC Abnahme'){
                setActiveField('ac_abnahme')
                for (let [key, value] of Object.entries(JSON.parse(res?.data?.ac_abnahme))) {
                    setValue(key, (value == null || value == 'null' ? "" : value ));
                    if(key == 'description'){
                        setDescription((value == null || value == 'null' ? "" : value ))
                    }
                }
            }else if(v == 'Reclamation'){
                setActiveField('reclamation')
                for (let [key, value] of Object.entries(JSON.parse(res?.data?.reclamation))) {
                    setValue(key, (value == null || value == 'null' ? "" : value ));
                    if(key == 'description'){
                        setDescription((value == null || value == 'null' ? "" : value ))
                    }
                }
            }else if(v == 'Ticket'){
                setActiveField('ticket')
                for (let [key, value] of Object.entries(JSON.parse(res?.data?.ticket))) {
                    setValue(key, (value == null || value == 'null' ? "" : value ));
                    if(key == 'description'){
                        setDescription((value == null || value == 'null' ? "" : value ))
                    }
                }
            } 
        });
    }

    const onSubmit = (formData) => {
        if(!appData?.api_url) return;
        
        
        formData.description = description;
        var data = {};
        data[activeField] = JSON.stringify(formData);
        data._method = 'PUT';
        data.appKey = appData?.appKey;
        
        setSaving(true);
        callFetch("app/smtp-email-configurations/" + 1, "POST", data, setError, appData?.api_url).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            //setSubmitSuccess(true);
        });
    };

    return(
        <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <div className="row g-3"> 
                <div className="col-md-4"> 
                    <label>Choose E-Mail</label>
                    <select className="form-control" onChange={(e) => loadText(e.target.value)}>
                        <option value="Angebot">{t('Angebot')}</option>
                        <option value="Order">{t('Order')}</option>
                        <option value="AC Planung">{t('AC Planung')}</option>
                        <option value="DC Planung">{t('DC Planung')}</option>
                        <option value="DC Zurückversetzen">{t('DC Zurückversetzen')}</option>
                        <option value="AC Zurückversetzen">{t('AC Zurückversetzen')}</option>
                        <option value="70 Invoice">{t('70% ') + t('Invoice')}</option>
                        <option value="30 Invoice">{t('30% ' + t('Invoice'))}</option>
                        <option value="70 Payment">{t('70% ' + t('Payment'))}</option>
                        <option value="30 Payment">{t('30% ' + t('Payment'))}</option>
                        <option value="Liefertermin Module">{t(t('Liefertermin Module'))}</option>
                        <option value="Liefertermin Speicher">{t(t('Liefertermin Speicher'))}</option>
                        <option value="Liefertermin Großhändler">{t(t('Liefertermin Großhändler'))}</option>
                        <option value="DC Abnahme">{t(t('DC Abnahme'))}</option>
                        <option value="AC Abnahme">{t(t('AC Abnahme'))}</option>
                        <option value="Reclamation">{t(t('Reclamation'))}</option>
                        <option value="Ticket">{t(t('Ticket'))}</option>
                    </select>
                    <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                </div> 
                <div className="col-md-4"> 
                    <label>Choose SMTP</label>
                    <select className="form-control">
                        <option>service@blw.com</option>
                        <option>noreply@blw.com</option>
                    </select>
                    <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                </div> 
                
                <div className="col-md-12"> 
                    <label>Subject</label>
                    <input
                        type="text" 
                        className="form-control"
                        placeholder={t('eg. Michael')}
                        {...register("subject")} />
                    <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                </div>    
                
                <div className="col-md-12"> 
                    <label style={{margin: '0px'}}>E-Mail Text</label> 
                    <span style={{fontSize: '12px', color: '#64748B', display: 'block', marginBottom: '.3rem'}}>This is how others will learn about the project, so make it good! </span>
                    <SoftEditor  onChange={setDescription} value={description} />
                    <div className="invalid-feedback">{errors.mobile && errors.mobile.message}</div>
                </div>    

            </div>
            <div className="col-12 mt-4">
                {!saving && (
                    <button type="submit" className="btn btn-primary">
                        {t('Save')}
                    </button>
                )}
                {saving && (
                    <button type="submit" className="btn btn-disabled" disabled>
                        {t('Saving ...')}
                    </button>
                )}
            </div>
        </form>
    );
}

export default Email;
