import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useParams } from "react-router-dom";
import callFetch from "helpers/callFetch";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography"
import SoftDropzone from "components/SoftDropzone";
import IndexTable from "./IndexTable";

function Attachment() {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [appData, setAppData] = useState({});
    const [attachments, setAttachment] = useState([]);

    const {
        register,
        handleSubmit,
        setError,
        reset,
        setValue,
        unregister,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        callFetch("apps/" + params?.id, "GET", {}).then((res) => {
            setAppData(res?.data);
        });
    }, [params?.id, refresh]);

    const handeleAttachment = (newData) => {
        let data = attachments;
        data[attachments.length] = newData;
        setAttachment(data);
        setValue('attachments', JSON.stringify(data));
    }


    const onSubmit = (formData) => {
        if (!appData?.api_url) return;

        formData.appKey = appData?.appKey;

        setSaving(true);
        callFetch("app/extra-email-attachment", "POST", formData, setError, appData?.api_url).then((res) => {
            setRefresh(refresh + 1);
            setAttachment([]);
            setValue('attachments', '');
            setSaving(false);
            if (!res.ok) return;
            clearForm();
            //notify();
        });
    };

    const clearForm = () => {
        reset({
            attachments: JSON.stringify([]),
        });

        setAttachment([]);
    }

    const dropZoneInitialize = () => {
        if (!appData?.api_url) return;
        return (
            <SoftDropzone
                key={'dfdf'}
                options={{
                    dictDefaultMessage: t('Drop files here to upload'),
                    //addRemoveLinks: true,
                    acceptedFiles: ".jpeg,.jpg,.png,.pdf",
                    action: appData?.api_url + 'app/attachment?appKey=' + appData?.appKey,
                    headers: {
                        "Accept": "application/json",
                        "Authorization": "Bearer " + Cookies.get('token')
                    },
                    processing: function () {
                        document.body.classList.add('loading');
                    },
                    success: (file, response) => {
                        document.body.classList.remove('loading');
                        if (response.message == 'success') {
                            handeleAttachment(response.data);
                        }
                    },
                    maxfilesexceeded: function (file) {
                        this.removeAllFiles();
                        this.addFile(file);
                    },
                    error: function (file, response) {
                        document.body.classList.remove('loading');
                    }
                }}
            />
        )
    }

    return (
        <>
            <div className="card mt-3" style={{ padding: '12px' }}>
                <div className="card-body  scroll-cs" style={{ maxHeight: '530px', padding: '0px' }}>
                    <h6 style={{ padding: '5px 0px 0px 12px' }}>{t('Upload Extra E-Mail Attachments')}</h6>
                    <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                        <input type="hidden" defaultValue="POST" {...register("_method")} />
                        <SoftBox>
                            <SoftBox p={2} lineHeight={1}>
                                <SoftBox>
                                    <SoftBox
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="flex-end"
                                        height="100%"
                                    >
                                        <SoftBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                {t('Files')}
                                            </SoftTypography>
                                        </SoftBox>

                                        {dropZoneInitialize()}

                                    </SoftBox>
                                </SoftBox>
                            </SoftBox>
                            <SoftBox pt={2} className="text-end">
                                <a href="#" className="btn btn-danger mx-2 mb-0" onClick={() => { clearForm() }}>
                                    {t('Cancel')}
                                </a>

                                {!saving && (
                                    <button type="submit" className="btn btn-primary mb-0">
                                        {t('Save')}
                                    </button>
                                )}
                                {saving && (
                                    <button type="submit" className="btn btn-disabled mb-0" disabled>
                                        {t('Saving ...')}
                                    </button>
                                )}
                            </SoftBox>

                        </SoftBox>
                    </form>
                </div>
            </div>

            <div className="card mt-3" style={{ padding: '12px' }}>
                <div className="card-body  scroll-cs" style={{ maxHeight: '530px', padding: '0px' }}>
                    <h6 style={{ padding: '5px 0px 0px 12px' }}>{t('Extra E-Mail Attachments')}</h6>
                    <IndexTable refresh={refresh}/>
                </div>
            </div>
        </>
    );
}

export default Attachment;
