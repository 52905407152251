/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import callFetch from "helpers/callFetch";
import { useParams } from "react-router-dom";

function Header({imageUrl, data}) {
  const params = useParams()
  const [visible, setVisible] = useState(true);
  const [user,setUser] = useState({});
  const [datas, setDatas] = useState();
  const [roleDesigned, setRoleDesigned] = useState({})
  const handleSetVisible = () => setVisible(!visible);
  useEffect(() => {
    if(params?.id){
      callFetch("employees/" + params.id + "/edit", "GET", []).then((res) => {
        setDatas(res?.data)
        setUser(res?.userData)
      });
    }
}, [params.id]);

const imageRender = () => {
  if (imageUrl) {
    return imageUrl;
  } else if (params?.id) {
    return process.env.REACT_APP_BACKEND_URL + user?.user?.photo;
  } else {
    return '/assets/img/placeholder.png';
  }
};

const returnImageUrl = imageRender();
  return (
    <Card id="profile">
      <SoftBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            {
              (!imageUrl && !params?.id) ?  <SoftAvatar
                                  src='/assets/img/placeholder.png'
                                  alt="profile-image"
                                  variant="rounded"
                                  size="xl"
                                  shadow="sm"
                                />:<SoftAvatar
                                      src={returnImageUrl}
                                      alt="profile-image"
                                      variant="rounded"
                                      size="xl"
                                      shadow="sm"
                                    />
            }
            
              
          </Grid> 
          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                {
                  !datas ? data?.name:datas.name
                }

              </SoftTypography>
              <SoftTypography variant="button" color="text" fontWeight="medium">
                {
                  user?.designation ? user?.designation?.name:data?.role
                }
                /
                {
                  user?.department ? user?.department?.name:data?.department_id
                }
                
              </SoftTypography>
            </SoftBox>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={3} sx={{ mr: "auto" }}>
            <SoftBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <SoftTypography variant="caption" fontWeight="regular">
                Switch to {visible ? "invisible" : "visible"}
              </SoftTypography>
              <SoftBox mx={1}>
                <Switch checked={visible} onChange={handleSetVisible} />
              </SoftBox>
            </SoftBox>
          </Grid> */}
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default Header;
