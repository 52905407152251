import { React, useState, useEffect } from "react";
import { NavLink,useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';
import callFetch from "../helpers/callFetch";
import { t } from "i18next";

function TopNav({ absolute, light, isMini }) {
    const [user, setUser] = useState(JSON.parse(Cookies.get('user')));
    const lang = Cookies.get('lang') ? JSON.parse(Cookies.get('lang')) : { flag: 'de', lang: 'de', name: 'Deutsch' };

    function doSignout(e) {
        e.preventDefault();

        callFetch('signout', 'POST', [], null).then(res => {
            Cookies.remove('user');
            Cookies.remove('token');
            window.location.href = process.env.REACT_APP_FRONTEND_URL;
        });
    }

    return (
        <>
            <nav className="navbar navbar-main navbar-expand-lg mt-4 top-1 px-0 mx-4 border-radius-xl position-sticky blur shadow-blur left-auto z-index-sticky" id="navbarBlur" navbar-scroll="true">
                <div className="container-fluid py-1 px-3">

                    <div className="collapse navbar-collapse" id="navbar">
                        <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                            
                        </div>

                        <ul className="navbar-nav justify-content-end">
                            <li className="nav-item d-xl-none mx-3 d-flex align-items-center mobile-menu-icon" style={{ position: 'absolute', left: '-0px', top: '28px' }}>
                                <a href="#0" className="nav-link text-body p-0" id="iconNavbarSidenav">
                                    <div className="sidenav-toggler-inner">
                                        <i className="sidenav-toggler-line" />
                                        <i className="sidenav-toggler-line" />
                                        <i className="sidenav-toggler-line" />
                                    </div>
                                </a>
                            </li>
                           
                            <li className="nav-item d-flex align-items-center">
                                <div className="ms-md-auto pe-md-3 d-flex">
                                    <div className="dropdown">
                                        <a href="flags" className="m-0 nav-link text-body font-weight-bold px-0" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src="https://aui.atlassian.com/aui/latest/docs/images/avatar-person.svg" alt="" style={{ height: "25px", width: "25px" }} />
                                        </a>
                                        <ul className="dropdown-menu res-dropdown-menu dropdown-menu-end border-0 shadow" aria-labelledby="dropdownMenuButton1">
                                            <div style={{ padding: "0px 15px" }}>
                                                <li className="">{user && user.name ? user.name : ''}</li>
                                                <li className="">{user && user.email ? user.email : ''}</li>
                                            </div>
                                            <div class="dropdown-divider"></div>
                                            <li><a href="#0" className="dropdown-item">{t('Dashboard')}</a></li>
                                            <li><NavLink to="/profile/settings" className="dropdown-item">{t('Settings')}</NavLink></li>
                                            <li><a href="#0" onClick={(e) => doSignout(e)} className="dropdown-item">{t('Sign Out')}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default TopNav;
