import { React, useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import TopNav from "./TopNav";
import MobileBottomNavigation from "./MobileBottomNavigation/index";
import { Outlet } from "react-router-dom";
import Cookies from 'js-cookie';

function PrivateLayout() {
    const [name, setName] = useState([]);

    useEffect(() => {
        setInterval(() => {
            //console.log(Cookies.get('UnveCC'));
            document.querySelectorAll(".deshboard-backdrop").forEach(el => el.remove());
            if (Cookies.get('UnveCC') && Cookies.get('UnveCC') == 'yes') {
                document.body.insertAdjacentHTML('beforeend', '<div class="deshboard-backdrop show"></div>');
            }
        }, 2000)
    }, [Cookies.get('UnveCC')]);

    return (
        <>
            <Sidebar />
            <main className="main-content position-relative mt-1 border-radius-lg" style={{height: '975px !important', overflow: 'hidden !important'}} id="main-content">
                <TopNav />
                <div className="container-fluid mt-4" style={{maxHeight: '835px'}}>
                    <Outlet />
                </div>
            </main>
            <div id="mobile-bottom-navigation">
                <MobileBottomNavigation name={name} />
            </div>
        </>
    );
}

export default PrivateLayout;
