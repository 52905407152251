// @mui material components
import Grid from "@mui/material/Grid";
import { React, useEffect, useState } from "react";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import Card from "@mui/material/Card";
// Settings page components 
import Header from "./components/Header";
import BasicInfo from "./components/BasicInfo";
import ChangePassword from "./components/ChangePassword"; 
import Notifications from "./components/Notifications"; 
import DeleteAccount from "./components/DeleteAccount";
// Soft UI Dashboard PRO React icons
import CompanyInfo from "./components/CompanyInfo";
import EmailSetting from "./components/EmailSetting";
function Settings() {
  const [headerStatus, setHeaderStatus] = useState(false);
  return ( 
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
          <Card
            sx={{
              borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
              position: "sticky",
              top: "10%",
            }}
          >
            <SoftBox>
                <div class="nav flex-column nav-pills me-3 settings-nav" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <button class="nav-link active font-size-14-rem" style={{textAlign: 'left'}} id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="true" onClick={() => setHeaderStatus(false)}>Profile</button>
                  <button class="nav-link font-size-14-rem" style={{textAlign: 'left'}} id="v-pills-change-password-tab" data-bs-toggle="pill" data-bs-target="#v-pills-change-password" type="button" role="tab" aria-controls="v-pills-change-password" aria-selected="false" onClick={() => setHeaderStatus(false)}>Change Password</button>
                  <button class="nav-link font-size-14-rem" style={{textAlign: 'left'}} id="v-pills-document-tab" data-bs-toggle="pill" data-bs-target="#v-pills-document" type="button" role="tab" aria-controls="v-pills-document" aria-selected="false" onClick={() => setHeaderStatus(true)}>Document</button>
                  <button class="nav-link font-size-14-rem" style={{textAlign: 'left'}} id="v-pills-email-tab" data-bs-toggle="pill" data-bs-target="#v-pills-email" type="button" role="tab" aria-controls="v-pills-email" aria-selected="false" onClick={() => setHeaderStatus(false)}>Email Settings</button>
               
                  <button class="nav-link font-size-14-rem" style={{textAlign: 'left'}} id="v-pills-notification-tab" data-bs-toggle="pill" data-bs-target="#v-pills-notification" type="button" role="tab" aria-controls="v-pills-notification" aria-selected="false" onClick={() => setHeaderStatus(false)}>Notifications</button>
                </div>
            </SoftBox>
          </Card>
           
          </Grid>
          <Grid item xs={12} lg={9}>
            <SoftBox mb={3}> 
              <Grid container spacing={3}>
                {!headerStatus && (
                  <Grid item xs={12}>
                    <Header />
                  </Grid>
                )}
                
                <div class="tab-content" id="v-pills-tabContent" style={{padding: '20px 0px 20px 20px', width: '100%'}}>
                  <div class="tab-pane fade show active" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabindex="0">
                    <Grid item xs={12} lg={12}>
                      <BasicInfo />
                    </Grid>
                  </div>
                  <div class="tab-pane fade" id="v-pills-document" role="tabpanel" aria-labelledby="v-pills-document-tab" tabindex="0">
                    <Grid item xs={12} lg={12}>
                      <CompanyInfo/>
                    </Grid> 
                  </div>
                  <div class="tab-pane fade" id="v-pills-email" role="tabpanel" aria-labelledby="v-pills-email-tab" tabindex="0">
                    <Grid item xs={12} lg={12}>
                    <Grid item xs={12} lg={12}>
                      <EmailSetting/>
                    </Grid>
                    </Grid>
                  </div>
                  <div class="tab-pane fade" id="v-pills-change-password" role="tabpanel" aria-labelledby="v-pills-change-password-tab" tabindex="0">
                    <Grid item xs={12} lg={12}>
                      <ChangePassword />
                    </Grid>
                  </div>
                  <div class="tab-pane fade" id="v-pills-notification" role="tabpanel" aria-labelledby="v-pills-notification-tab" tabindex="0">
                    <Grid item xs={12} lg={12}>
                      <Notifications />
                    </Grid> 
                  </div> 
                  <div class="tab-pane fade" id="v-pills-delete-account" role="tabpanel" aria-labelledby="v-pills-delete-account-tab" tabindex="0">
                    <Grid item xs={12} lg={12}>
                      <DeleteAccount />
                    </Grid>
                  </div>
                </div>  
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox> 
  );
}

export default Settings;
