import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import IndexTable from './IndexTable';
import callFetch from "../../helpers/callFetch";

function Index(props) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);

    const updateNow = () => {
        setLoading(true);
        
        callFetch("apps/update-apps", "GET", {}).then((res) => {
            setLoading(false);
            setRefresh(refresh+1);
        });
    }

    return (
        <>
            <div className="d-sm-flex justify-content-between">
                <div>
                    {JSON.parse(Cookies.get('permissions')).indexOf("appC") !== -1 ? (
                        <NavLink to="/apps/create" className="btn btn-icon btn-primary">
                            {t('Add apps')}
                        </NavLink>
                    ) : <></>}

                    {JSON.parse(Cookies.get('permissions')).indexOf("appC") !== -1 ? (
                        <button
                            className={"btn btn-icon ms-3 "+(loading ? 'btn-secondary' : 'btn-primary')}
                            onClick={() => {
                                if (!loading) {
                                    updateNow();
                                }
                            }}
                        >
                            {loading ? t('Updating ...') : t('Update Now')}
                        </button>
                    ) : <></>}
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('All Apps')}</h6>
                        </div>
                        <div className="card-body px-0 pt-0 pb-2">
                            <IndexTable refresh={refresh} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Index;
