import { React, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import Cookies from 'js-cookie';
import callFetch from "helpers/callFetch";
import SoftAlert from "components/SoftAlert";

function ArtisanCommand() {
    let params = useParams();
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [refresh2, setRefresh2] = useState(0);
    const [data, setData] = useState({});
    const [appData, setAppData] = useState({});
    const [commands, setCommands] = useState([
        {
            'name': 'Cache Clear',
            'command': 'cache:clear',
            'saving': false,
            'description': 'Clear Backend Cache'
        },
        {
            'name': 'Route Clear',
            'command': 'route:clear',
            'saving': false,
            'description': 'Clear Backend Route'
        },
        {
            'name': 'View Clear',
            'command': 'view:clear',
            'saving': false,
            'description': 'Clear Backend View'
        },
        {
            'name': 'Config Clear',
            'command': 'config:clear',
            'saving': false,
            'description': ' Configuration cache clear'
        },
        {
            'name': 'Migrate',
            'command': 'migrate',
            'saving': false,
            'description': ' Run migration'
        },
        {
            'name': 'Storage Link',
            'command': 'storage:link',
            'saving': false,
            'description': ' Generate Storage Link'
        },
        {
            'name': 'key:generate',
            'command': 'key:generate',
            'saving': false,
            'description': 'Generate app key'
        },
    ]);

    const {
        register,
        reset,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        callFetch("apps/" + params?.id, "GET", {}).then((res) => {
            setAppData(res?.data);
        });
    }, [params?.id, refresh]);

    const onSubmit = (formData, index) => {
        if (!appData?.api_url) return;

        var updateCommands = commands;
        updateCommands[index].saving = true;
        setCommands(updateCommands);
        setRefresh2(refresh2 + 1)

        callFetch("app/artisan" + '?appKey=' + appData?.appKey, "POST", formData, setError, appData?.api_url).then((res) => {
            var updateCommands = commands;
            updateCommands[index].saving = false;
            setCommands(updateCommands);
            setRefresh2(refresh2 + 2)
        });
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('Artisan Command')}</h6>
                        </div>
                        <div className="card-body">
                            <div className="row">

                                {commands && commands.map((command, index) => (
                                    <>
                                        <div className="col-12">
                                            <p className="m-0">{command?.description}</p>
                                            {command?.saving ? (
                                                <button
                                                    type="button"
                                                    className="btn btn-disabled"
                                                    disabled
                                                >
                                                    {t('Running')} ...
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={() => {
                                                        onSubmit(command, index);
                                                    }}
                                                    type="button"
                                                    className="btn btn-primary">
                                                    {t(command?.name)}
                                                </button>
                                            )}

                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ArtisanCommand;
