import { React, useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EmployeeIndexTable from "./EmployeeIndexTable";
import Cookies from "js-cookie";
import SoftSnackbar from "components/SoftSnackbar";

function EmployeeIndex() {
  let params = useParams();
  const { t } = useTranslation();
  let { status } = useParams();

  const [successSB, setSuccessSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const renderSuccessSB = (
    <SoftSnackbar
      color="success"
      icon="check"
      title="Success"
      content="Changes saved successfully"
      dateTime="Just Now"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  useEffect(() => {
    console.log(status);
    if (status == "all") {
      openSuccessSB();
    }
  }, []);

  return (
    <>
      <div className="d-sm-flex justify-content-between">
        <div className="relative-h">
          {Cookies.get("permissions").indexOf("EC") !== -1 ? (
            <NavLink
              to={'/apps/'+params?.id+'/user_setup/create'}
              className="btn btn-icon btn-primary"
            >
              {t("Add Employee")}
            </NavLink>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card mb-4">
            <div className="card-header pb-0">
              <h6>{t("All Employees")}</h6>
            </div>
            <div className="card-body px-0 pt-0 pb-2 projects-menu">
              <div style={{ padding: '0px 15px' }}>
                <ul class="nav nav-tabs nav-underline" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="active-user-tab" data-bs-toggle="tab" data-bs-target="#active-user-tab-pane" type="button" role="tab" aria-controls="active-user-tab-pane" aria-selected="true" style={{ color: '#0048B1', fontSize: '0.875rem' }}>{t('Aktive User')}</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="inactive-user-tab" data-bs-toggle="tab" data-bs-target="#inactive-user-tab-pane" type="button" role="tab" aria-controls="inactive-user-tab-pane" aria-selected="false" style={{ color: '#0048B1', fontSize: '0.875rem' }}>{t('Deaktive User')}</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="all-user-tab" data-bs-toggle="tab" data-bs-target="#all-user-tab-pane" type="button" role="tab" aria-controls="all-user-tab-pane" aria-selected="false" style={{ color: '#0048B1', fontSize: '0.875rem' }}>{t('All')}</button>
                  </li>
                </ul>
              </div>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active p-3" id="active-user-tab-pane" role="tabpanel" aria-labelledby="active-user-tab" tabindex="0">
                  <EmployeeIndexTable status={'aktivieren'} />
                </div>
                <div class="tab-pane fade p-3" id="inactive-user-tab-pane" role="tabpanel" aria-labelledby="inactive-user-tab" tabindex="0">
                  <EmployeeIndexTable status={'deaktivieren'} />
                </div>
                <div class="tab-pane fade p-3" id="all-user-tab-pane" role="tabpanel" aria-labelledby="all-user-tab" tabindex="0">
                  <EmployeeIndexTable status={''} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {renderSuccessSB}
      </div>

      {/* <ImportModal/> */}
    </>
  );
}

export default EmployeeIndex;
