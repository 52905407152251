import Cookies from 'js-cookie';
import { toast, Bounce } from 'react-toastify';
import SoftSnackbar from "components/SoftSnackbar";
import Icon from "@mui/material/Icon";

export function callFetch(urlSegment, fetchMethod, data, setError, apiUrl = '', notification = true) {

    if (!urlSegment || !fetchMethod)
        return {};

    let bodyData = makeFormData(fetchMethod, data);
    let isOk = null;

    //return fetch(process.env.REACT_APP_API_URL + urlSegment, bodyData)
    return fetch((apiUrl ? apiUrl : process.env.REACT_APP_API_URL) + urlSegment, bodyData)
        .then(res => {
            isOk = res.ok;
            return res.json();
        })
        .then(resData => {
            if (!isOk && resData.errors) {
                showServerErrors(setError, resData.errors);
                //toast.error(resData.message);
            }
            else {
                console.log('resData')
                console.log(resData)
                console.log(isOk)

                if (fetchMethod !== 'GET' && urlSegment != 'signin') {
                    if (isOk === false || resData?.error || resData?.exception || resData?.errors?.length > 0) {
                        toast.error(resData?.error ? resData?.error : resData?.message, {
                            position: "bottom-right",
                            autoClose: 5000,
                            limit: 1,
                            hideProgressBar: true,
                            newestOnTop: true,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                            transition: Bounce,
                            className: 'toast-error-message',
                        });
                    } else if(notification === true) {
                        toast.success(resData?.message ? resData?.message : 'Successful', {
                            position: "bottom-right",
                            autoClose: 5000,
                            limit: 1,
                            hideProgressBar: true,
                            newestOnTop: true,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                            transition: Bounce,
                            className: 'toast-success-message',
                            icon: <span class="material-icons-round notranslate MuiIcon-root MuiIcon-fontSizeSmall" aria-hidden="true">check</span>
                        });
                    }
                }
            }
            resData.ok = isOk;
            return resData;
        });
}

function makeFormData(fetchMethod, data) {
    let formData = new FormData();
    for (let [key, value] of Object.entries(data)) {
        if (value instanceof FileList) {
            if (typeof value[0] !== 'undefined') {
                for (let i = 0; i < value.length; i++) {
                    formData.append(`${value.length > 1 ? key + '[]' : key}`, value[i]);
                }
            }
        }
        else
            formData.append(key, value);
    }
    let bodyData = {
        method: fetchMethod,
        headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + Cookies.get('token')
        }
    };
    if (fetchMethod !== 'GET')
        bodyData.body = formData;
    return bodyData;
}

function showServerErrors(setError, errorData) {
    if (!setError)
        return;

    for (let [key, value] of Object.entries(errorData)) {
        setError(key, {
            type: "server",
            message: value[0]
        });
        if (document.querySelector("*[name='" + key + "']"))
            document.querySelector("*[name='" + key + "']").setCustomValidity('invalid');
    }
}

export default callFetch;
