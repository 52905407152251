import { React, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import Cookies from 'js-cookie';
import callFetch from "helpers/callFetch";
import SoftAlert from "components/SoftAlert";
//import UserTable from "./UserTable";
import UserTable from "../users/EmployeeIndex";

function User() {
    let params = useParams();
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState('database_setup');
    const [saving, setSaving] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [data, setData] = useState({});
    const [appData, setAppData] = useState({});
    const [refresh, setRefresh] = useState(0);

    const {
        register,
        reset,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {

        callFetch("apps/" + params?.id, "GET", {}).then((res) => {
            setAppData(res?.data);
        });

    }, [params?.id, refresh]);

    const onSubmit = (formData) => {
        if (!appData?.api_url) return;
        setSaving(true);
        setData({});

        formData.appKey = appData?.appKey;

        callFetch("create-user", "POST", formData, setError, appData?.api_url).then((res) => {
            setSaving(false);
            if (!res.ok) return;
            setData(res);
            setRefresh(refresh + 1);
            reset({});
        });
    };

    return (
        <>
            <div className="row">
                {/*
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-header pb-0">
                            <h6>{t('User Setup')}</h6>
                        </div>
                        <div className="card-body">
                            <form className={`needs-validation ${Object.keys(errors).length ? "was-validated" : ""}`} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                <div className="row col-md-6">
                                    <div className="col-md-12">
                                        <label>{t('Name')}</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            {...register("name", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.name && errors.name.message}</div>
                                    </div>

                                    <div className="col-md-12">
                                        <label>{t('Email')}</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            {...register("email", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.email && errors.email.message}</div>
                                    </div>

                                    <div className="col-md-12">
                                        <label>{t('Password')}</label>
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            {...register("password", {
                                                required: true,
                                            })}
                                            required
                                        />
                                        <div className="invalid-feedback">{errors.password && errors.password.message}</div>
                                    </div>

                                    <div className="col-12 mt-4">

                                        {data?.status === 'success' ? (
                                            <SoftAlert color="success" dismissible>
                                                {data?.message}
                                            </SoftAlert>
                                        ) : data?.status === 'error' ? (
                                            <SoftAlert color="error" dismissible>
                                                {data?.message}
                                            </SoftAlert>
                                        ) : ''}

                                        {!saving && (
                                            <button type="submit" className="btn btn-primary">
                                                {t('Create user')}
                                            </button>
                                        )}
                                        {saving && (
                                            <button type="submit" className="btn btn-disabled" disabled>
                                                {t('Creating')} ...
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                */}

                <div className="col-12 mt-3">
                    <UserTable refresh={refresh} />
                </div>
            </div>
        </>
    );
}

export default User;
